import request from '@/utils/request';
// 登录
export function webhook(data) {
  return request({
    data,
    //url: 'http://44.211.221.95:5055/webhook',
    url: 'https://chatbot.easysolarsupply.com/webhook',
    // url:"/",
    method: 'post',
    // baseURL:'/webhook',
  })
}
export function webhooks(data) {
  return request({
    data,
    //url: 'http://44.211.221.95:5005/webhooks/rest/webhook',
    url: 'https://chatbot1.easysolarsupply.com/webhooks/rest/webhook',
    method: 'post',
    // baseURL:'webhooks',
  })
}

// 新增地址 
export function addAddress(data) {
  return request({
    url: '/user/user_address_save/personal/config',
    method: 'post',
    data,
  })
}
// 地址列表 
export function getAddress(data) {
  return request({
    url: '/user/user_address_list/personal/config',
    method: 'post',
    data,
  })
}

// 删除地址 
export function deleteAddress(data) {
  return request({
    url: '/user/user_address_delete/personal/config',
    method: 'post',
    data,
  })
}

// 编辑地址 
export function editAddress(data) {
  return request({
    url: '/user/user_address_modify/personal/config',
    method: 'post',
    data,
  })
}

// 获取用户信息
export function getUserProfile(data) {
  return request({
    url: '/user/userProfile/get',
    method: 'post',
    data,
  })
}

// 编辑用户信息
export function editUserProfile(data) {
  return request({
    url: '/user/userProfile/modify',
    method: 'post',
    data,
  })
}

// 编辑用户信息
export function editCommunicationPreferences(data) {
  return request({
    url: '/user/userProfile/CommunicationPreferences/add',
    method: 'post',
    data,
  })
}

//获取用户信息
export function getCommunicationPreferences(data) {
  return request({
    url: '/user/userProfile/CommunicationPreferences/get',
    method: 'post',
    data,
  })
}





