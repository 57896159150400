<template>
  <PageSlot>
    <router-view v-if="$route.meta && $route.meta.isNotKeepalive"></router-view>
    <template v-else>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </template>
  </PageSlot>
</template>

<script>
// import router from "@/router/index";
import PageSlot from "@/views/PageSlot.vue";
export default {
  name: "App",
  components: { PageSlot },
  // computed: {
  //   showHeader() {
  //     const options = router.options.routes.filter(
  //       (item) => item.meta?.hasHeader
  //     );
  //     const op = options.find((item) => item.name === this.$route.name);
  //     return !!op
  //   },
  // },
};
</script>
<style>
/* 你的样式 */
.rc-anchor-container {
  display: none;
}
</style>