<template>
  <div class="desc-container" ref="descContainer" @mouseenter="descHover">
    <el-tooltip
      :disabled="disabled"
      :content="desc"
      placement="top-start"
      popper-class="desc-tip"
    >
      <div :class="['desc', lines !== 1 ? 'many-line' : '']">{{ desc }}</div>
    </el-tooltip>
    <div class="desc-all" ref="descContent">{{ desc }}</div>
  </div>
</template>
<script>
export default {
  props: {
    desc: {
      type: String,
      default: "",
    },
    lines: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    descHover() {
      const container = this.$refs.descContainer;
      const content = this.$refs.descContent;
      this.disabled = container.offsetHeight >= content.offsetHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.desc-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  font-size: inherit;
  .desc {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: inherit;
    &.many-line {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  .desc-all {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 200%;
  }
}
</style>
