
<template>
  <div id="app" class="all-page-container">
    <HeadersList />
    <div class="page-slot-content">
      <slot></slot>
    </div>
    <FooterList />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.all-page-container {
  display: flex;
  flex-flow: column;
  .page-slot-content {
    // flex: 1;
    // overflow: auto;
  }
}
</style>
