import { login } from '@/api/api';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // component: HomeView
    component: () => import('../views/index.vue'),
    meta: {
      hasHeader: true,
      title: 'Home'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      hasHeader: true,
      title: 'About'
    }
  },
  {
    path: '/headersList',
    name: 'headersList',
    component: () => import('../views/HeadersList.vue')
  },

  {
    path: '/residential',
    name: 'residential',
    component: () => import('../views/Residential.vue'),
    meta: {
      hasHeader: true,
      title: 'Residential'
    }
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: () => import('../views/Commercial.vue'),
    meta: {
      hasHeader: true,
      title: 'Commercial'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products.vue'),
    meta: {
      hasHeader: true,
      title: 'Products'
    }
  },
  {
    path: '/howtopurchase',
    name: 'purchase',
    component: () => import('../views/Purchase.vue'),
    meta: {
      hasHeader: true,
      title: 'How to purchase'
    }
  },
  {

    path: '/services',
    name: 'service',
    component: () => import('../views/Service.vue'),
    meta: {
      hasHeader: true,
      title: 'Services'
    }
  },
  {
    path: '/ecosystem',
    name: 'ecosystem',
    component: () => import('../views/Ecosystem.vue'),
    meta: {
      hasHeader: true,
      title: 'Ecosystem'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/AboutUs.vue'),
    meta: {
      hasHeader: true,
      title: 'About Us'
    }
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('../views/ProductDetails.vue'),
    meta: {
      isNotKeepalive: true,
      hasHeader: true,
      title: 'Product Details'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order.vue'),
    meta: {
      hasHeader: true,
      title: 'Order'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      isNotKeepalive: true,
      title: 'Login'
    }
  }, {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue'),
    meta: {
      isNotKeepalive: true,
      title: 'Test'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      isNotKeepalive: true,
      title: 'Register'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      pageName: "My Account",
      isNotKeepalive: true,
      title:"Dashboard"
    } 
  }, {
    path: '/orders/:order_type?',
    name: 'orders',
    component: () => import('../views/components/Orders.vue'),
    meta: {
      pageName: "My Orders",
      isNotKeepalive: true,
      title:"Orders"
    }
  }, {
    path: '/designOrder/:order_type?',
    name: 'designOrder',
    component: () => import('../views/components/DesignOrder.vue'),
    meta: {
      pageName: "My Design orders",
      title:"Design Orders"
    }
  }, {
    path: '/SolutionOrders/:order_type?',
    name: 'SolutionOrders',
    component: () => import('../views/components/SolutionOrders.vue'),
    meta: {
      pageName: "My Solution orders",
      title:"Design Orders"
    }
  }, {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/components/Cart.vue'),
    meta: {
      pageName: "My Cart",
      title:"Cart",
      isNotKeepalive: true
    }
  },
  , {
    path: '/wishlists',
    name: 'wishlists',
    component: () => import('../views/components/Wishlists.vue'),
    meta: {
      pageName: "My Wishlists",
      title:"Wishlists",
    }
  },
  {
    path: '/addressManage',
    name: 'addressManage',
    component: () => import('../views/components/AddressManage.vue'),
    meta: {
      pageName: "My Address",
      title:"Address",
      isNotKeepalive: true
    }
  },
  {
    path: '/Partner',
    name: 'Partner',
    component: () => import('../views/Partner.vue'),
    meta: {
      hasHeader: true
    }
  },
  {
    path: '/Easysolarsupply',
    name: 'Easysolarsupply',
    component: () => import('../views/Easysolarsupply.vue'),
    meta: {
      hasHeader: true
    }
  },
  {
    path: '/Corporation',
    name: 'Corporation',
    component: () => import('../views/Corporation.vue'),
    meta: {
      hasHeader: true
    }
  },
  {
    path: '/personage',
    name: 'personage',
    component: () => import('../views/personage.vue'),
    meta: {
      hasHeader: true
    }
  },
  {
    path: '/applyFor',
    name: 'applyFor',
    component: () => import('../views/ApplyFor.vue'),
    meta: {
      hasHeader: true
    }
  },
  {
    path: '/applyForDetail',
    name: 'applyForDetail',
    component: () => import('../views/ApplyForDetail.vue'),
    meta: {
      hasHeader: true
    }
  }
  , {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/Pay.vue'),
    meta: {
      hasHeader: true,
      title: 'Pay'
    }

  }, {
    path: '/Selection',
    name: 'Selection',
    component: () => import('../views/Selection.vue'),
    meta: {
      hasHeader: true,
      isNotKeepalive: true,
      title: 'Selection'
    }
  }, {
    path: '/calculator',
    name: 'calculator',
    component: () => import('../views/Calculator.vue'),
    meta: {
      hasHeader: true,
      title: 'Calculator'
    }
  },
  {
    path: '/header',
    name: 'header',
    component: () => import('../views/components/Header.vue')
  }, {
    path: '/stepList',
    name: 'stepList',
    component: () => import('../views/components/StepList.vue'),
  }, {
    path: '/yourHouse',
    name: 'yourHouse',
    component: () => import('../views/components/YourHouse.vue')
  }, {
    path: '/yourBill',
    name: 'yourBill',
    component: () => import('../views/components/YourBill.vue')
  },
  {
    path: '/yourUse',
    name: 'yourUse',
    component: () => import('../views/components/YourUse.vue')
  },
  {
    path: '/yourReport',
    name: 'yourReport',
    component: () => import('../views/components/YourReport.vue')
  }, {
    path: '/installer',
    name: 'installer',
    component: () => import('../views/Installer.vue'),
    meta: {
      hasHeader: true,
      title: 'Installer'
    }
  }
  , {
    path: '/paySuccessful',
    name: 'paySuccessful',
    component: () => import('../views/components/PaySuccessful.vue'),
    meta: {
      hasHeader: true,
      title: 'Pay Successful'
    }
  }, {
    path: '/consultation',
    name: 'consultation',
    component: () => import('../views/Consultation.vue'),
    meta: {
      hasHeader: true,
      title: 'Consultation'
    }
  }, {
    path: '/design',
    name: 'design',
    component: () => import('../views/Design.vue'),
    meta: {
      hasHeader: true,
      title: 'Design'
    }
  }, {
    path: '/manual',
    name: 'manual',
    component: () => import('../views/Manual.vue'),
    meta: {
      hasHeader: true,
      title: 'Manual'
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      hasHeader: true,
      title: 'Contact',
    }
  }, {
    path: '/designCost',
    name: 'designCost',
    component: () => import('../views/DesignCost.vue'),
    meta: {
      hasHeader: true,
      title: 'Design Cost'
    }

  }, {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/OrderDetails.vue'),
    meta: {
      hasHeader: true,
      title: 'Order Details'
    }
  }, {
    path: "/dashboardOrderDetial",
    name: "dashboardOrderDetial",
    component: () => import('../views/components/DashboardOrderDetial.vue'),
    meta: {
      pageName: "My Order Detail",
      isNotKeepalive: true,
      hasHeader: true,
      title: "Order Detail"
    }
  }, {
    path: "/profile",
    name: "profile",
    component: () => import('../views/components/Profile.vue'),
    meta: {
      pageName: "My Profile",
      isNotKeepalive: true,
      title: "Profile"
    }
  }, {
    path: "/consultants",
    name: "consultants",
    component: () => import('../views/Consultants.vue'),
    meta: {
      hasHeader: true,
      title: "Consultants"
    }
  }, {
    path: "/footerList",
    name: "footerList",
    component: () => import('../views/FooterList.vue'),
    meta: {
      hasHeader: true,
      title: "Footer List"
    }
  }, {
    path: "/warranty",
    name: "warranty",
    component: () => import('../views/Warranty.vue'),
    meta: {
      hasHeader: true,
      title: "Warranty"
    }
  }, {
    path: "/contractor",
    name: "contractor",
    component: () => import('../views/Contractor.vue'),
    meta: {
      hasHeader: true,
      title: "Contractor"
    }
  }
  , {
    path: "/messages",
    name: "messages",
    component: () => import('../views/Messages.vue'),
    meta: {
      pageName: "My Messages",
      isNotKeepalive: true,
      title: "Messages"
    }
  }, {
    path: "/article/:id",
    name: "ArticleView",
    component: () => import('../views/ArticleView.vue'),
    meta: {
      hasHeader: true,
      title: "Article"  
    }
  }, {
    path: "/statemap",
    name: "Statemap",
    component: () => import('../views/StateMap.vue'),
    meta: {
      hasHeader: true,
      title: "State Map"
    }
  }, {
    path: "/slide",
    name: "slide",
    component: () => import('../views/slide.vue')
  }
  , {
    path: "/policy/:label",
    name: "policy",
    component: () => import('../views/Policy.vue'),
    meta: {
      hasHeader: true,
      title: "Policy"
    }
  }
  , {
    path: "/productsPrivacy",
    name: "productsPrivacy",
    component: () => import('../views/ProductsPrivacy.vue'),
    meta: {
      hasHeader: true,
      title: "Products Privacy"
    }
  }
  , {
    path: "/afterSalesService",
    name: "afterSalesService",
    component: () => import('../views/AfterSalesService.vue'),
    meta: {
      hasHeader: true,
      title: "After Sales Service"
    }
  }, {
    path: "/logisticsAgreement",
    name: "logisticsAgreement",
    component: () => import('../views/LogisticsAgreement.vue'),
    meta: {
      hasHeader: true,
      title: "Logistics Agreement"
    }
  }, {
    path: "/HappyHolidays",
    name: "HappyHolidays",
    component: () => import('../views/HappyHolidays.vue'),
    meta: {
      hasHeader: true,
      title: "Happy Holidays"
    }
  }
  , {
    path: "/quote",
    name: "Quote",
    component: () => import('../views/Quote.vue'),
    meta: {
      hasHeader: true,
      title: "Quote"
    }
  },{
    path:"/ForgotPwd",
    name:"forgotPwd",
    component:()=>import('../views/ForgotPwd.vue'),
    meta:{
      title:"ForgotPwd"
    }
  },{
    path:"/VerificationLogin",
    name:"VerificationLogin",
    component:()=>import('../views/VerificationLogin.vue'),
    meta:{
      title:"VerificationLogin"
    }
  },{
    path:"Waybill",
    name:"Waybill",
    component:()=>import('../views/Waybill.vue'),
    meta:{
      title:"Waybill"
    }
  },{
    path:"/PaymentCancelled",
    name:"PaymentCancelled",
    component:()=>import('../views/PaymentCancelled.vue'),
    meta:{
      title:"PaymentCancelled"
    }
  }
]

const scrollBehavior = function scrollBehavior(to, from, position) {
  return { x: 0, y: 0 }
};
// LogisticsAgreement 
const router = new VueRouter({
  routes,
  mode: 'history', // 设置模式为 'history'
  scrollBehavior
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) { 
  return originalPush.call(this, location).catch(err => err) 
}

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // const token = localStorage.getItem('token');
  const token = Cookies.get("token");
  if (requiresAuth && !token) {
    Vue.prototype.$message({
      message: 'The login has timed out, please log in again',
      type: 'warning'
    });
  } else {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next(); // 继续导航
  }
});
export default router