<template>
  <div>
    <ChatTool />
    <!-- <div v-if="showHappy && isIndexPage" class="happyBox">
      <img class="happy" src="/img/happy.gif" alt="" />
    </div> -->
    <button v-if="!isIndexPage" class="homePage" @click="goHome">
      Return to homepage
    </button>
    <div class="top-header d-lg-block d-none">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-7 col-md-7">
            <div class="top-header-infor">
              <div class="solution">
                <p v-if="packageInfo.length > 0 && isIndexPage && state_name">
                  Your Location: <span>{{ state_name }}</span
                  >, Annual equivalent hours of photovoltaic sunshine:
                  {{ packageInfo[0].minimum_equivalent_hours }}(h)~
                  {{ packageInfo[0].maximum_equivalent_hours }}(h),
                  <span class="click_a" @click="goToStateMap">Click here</span>
                  to know more.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 top-header-users">
            <div class="top-header-user">
              <ul>
                <!-- <li class="login-item"> 
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      <i class="bi bi-globe-americas"></i>
                      <span style="color: #212529">{{
                        $t("message." + currentLanguage)
                      }}</span>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="en" style="color: #212529">{{
                        $t("message.en")
                      }}</el-dropdown-item>
                      <el-dropdown-item command="de" style="color: #212529">{{
                        $t("message.de")
                      }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li> -->
                <li class="login-item">
                  <div id="English">{{ $t("English") }}</div>
                </li>
                <li class="login-item">
                  <i class="bi bi-person-circle"></i>
                  <el-dropdown v-if="user">
                    <span class="el-dropdown-link">
                      <router-link
                        style="color: #212529 !important"
                        :to="{ name: 'dashboard' }"
                        >
                        {{ user}}
                        <i class="el-icon-arrow-down el-icon--right"></i
                      ></router-link>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="My_Account">{{
                        $t("My Account")
                      }}</el-dropdown-item>
                      <el-dropdown-item @click.native="logout">{{
                        $t("logout")
                      }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <a v-else 
                  @click="login" style=" cursor: pointer !important;" id="login">{{
                    $t("Login")
                  }}</a>
                </li>
                <li>
                  <i
                    @click="messageButton"
                    class="iconfont icon-xiaoxi message_icon"
                  ></i>
                  <div
                    v-if="messageCount.length"
                    @click="messageButton"
                    class="message-badge"
                  >
                    <span>{{ messageCount }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top-navList">
      <div class="banner">
        <div class="container">
          <div class="original-box d-lg-block d-none">
            <div class="row align-items-center">
              <div class="col-lg-2 col-xl-2 col-md-2">
                <div class="logo">
                  <router-link to="/">
                    <img src="/img/nav_logo_2.png" alt="" />
                  </router-link>
                </div>
              </div>
              <div class="col-lg-7 col-xl-7 col-md-7">
                <div class="nav-title">
                  <!-- v-html="HeaderContent" -->
                  <!-- One-Stop Solar Products Integrator -->
                  {{ $t("message.One_StopSolarProductsIntegrator") }}
                </div>
              </div>
              <div class="col-lg-3 col-xl-3 col-md-3 head-btn align-center">
                <div class="head-right">
                  <div class="head-cart">
                    <i
                      id="shoppingCart"
                      @click="toggleCart"
                      class="iconfont icon-gouwucheman"
                    ></i>
                  </div>
                  <div class="header-button">
                    <router-link to="/quote">
                      {{ $t("message.Get_A_Quote") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="original-box original_box d-lg-block d-none">
        <div class="container">
          <div class="row">
            <div class="top-nav-menu col-md-12">
              <!-- /* plan-banner plan-commercial col-flex */ -->
              <el-menu
                background-color="#fff"
                text-color="#333"
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                router
                style="list-style: square !important"
              >
                <template v-for="(item, index) in blogHome">
                  <el-menu-item
                    :key="index"
                    :index="item.path"
                    v-if="item.children.length == 0"
                    v-show="item.isShow"
                    class="no-select liSelect"
                  >
                    {{ item.category_name }}
                  </el-menu-item>
                  <el-submenu
                    v-else
                    :key="index"
                    :index="item.path"
                    class="el-menu-item"
                  >
                    <template slot="title">
                      <router-link
                        :to="item.path"
                        class="submenu-title no-select liSelects"
                      >
                        <span class="liSelects">{{ item.category_name }}</span>
                      </router-link>
                    </template>
                    <el-menu-item
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                      :index="child.path"
                      class="no-select child_select_name"
                    >
                      {{ child.category_name }}
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--自适应导航栏-->
    <!-- el-menu-item no-select liSelect -->
    <div class="responsive-box d-lg-none">
      <div class="pos-f-t">
        <nav class="navbar navbar-dark">
          <div class="nav-titles">
            <router-link to="/">Easy Solar Supply</router-link>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            @click="toggleNav"
            data-toggle="collapse"
            data-target="#mobileNavbar"
            aria-controls="mobileNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon custom-toggler-icon"></span>
          </button>
        </nav>
        <div class="collapse" id="mobileNavbar" v-if="isNavOpen">
          <div class="mobile-head">
            <ul class="nav_scroll">
              <li v-for="item in navItems" :key="item.id">
                <a :href="item.path">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- 引入组件库 -->
<script src="https://unpkg.com/element-ui/lib/index.js"></script>
<script src="//at.alicdn.com/t/c/font_4719356_3ikvpozcz7l.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
<script>
import {
  getBlogHome,
  postRouterPath,
  postMessage,
  postStateSolution,
  postSolarAdmin,
} from "@/api/api";
import axios from "axios";
import ChatTool from "../views/ChatTool.vue";
import { EventBus } from "@/utils/eventBus";
import { mapState } from "vuex";
import Cookies from 'js-cookie'
export default {
  name: "HeadersList",
  components: { ChatTool },
  data() {
    return {
      currentLanguage: "en",
      isNavOpen: false,
      activeIndex: "1",
      navItems: [],
      isDropdownOpen: false,
      isOpen: {},
      user: "",
      blogHome: [],
      router: "",
      messageList: [],
      state: "California", // 假设用户所在州为加利福尼亚
      createdpackageInfo: {},
      city: "",
      latitude: null,
      longitude: null,
      messageCount: "",
      apiKey: "AIzaSyAsjDyAQFKHrRgU1ceGWNVTXjNdMZsv7ts",
      state_name: "",
      packageList: {},
      packageInfo: [],
      showHappy: true, // 假设 showHappy 默认为 true
    };
  },
  computed: {
    isIndexPage() {
      return this.$route.path === "/";
    },
    lastItem() {
      return this.navItems[this.navItems.length - 1];
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.activeIndex = val.path;
      },
    },
  },
  created() {
      // 获取 userInfo Cookie 并设置 user
    EventBus.$on("user-login", (user) => {
    this.user = user;
    console.log("User after login event:", this.user);
  });
  const user=this.getUserInfoFromCookie()||""
  this.user=user.first_name+user.last_name
  
  this._postMessage();
  // this._postSolarAdmin()
  // this.getLocation().then(() => {
  // });
  this._postStateSolution();
  this.getBlogHome()
  },
  beforeDestroy() {
    // 移除事件监听器以防止内存泄漏
    EventBus.$off("user-login");
  },
  methods: {
    
    login() {
      this.$router.push("/VerificationLogin?redirect="+btoa(this.$route.fullPath));
    },
    getUserInfoFromCookie() {
    const userInfoCookie = Cookies.get('userInfo');
    if (userInfoCookie) {
      try {
        // URL 解码
        const decodedCookie = decodeURIComponent(userInfoCookie);
        // 解析 JSON
        const userInfo = JSON.parse(decodedCookie);
        return userInfo;
      } catch (error) {
        console.error('Failed to parse userInfo cookie:', error);
        return null;
      }
    }
    return null;
  },
    goHome() {
      this.$router.push({ name: "index" });
    },
    async _postStateSolution() {
      const res = await postStateSolution();
      this.packageInfo = res.data.data;
      this.state_name = this.packageInfo[0]?.state_name || "No data available";
    },
    async getBlogHome() {
      // const blogHome = localStorage.getItem("blogHome")
      const blogHome=Cookies.get("blogHome")
      if (blogHome) {
        this.blogHome = JSON.parse(blogHome)
        return;
      }
      const res = await getBlogHome();
      if (res.data.code == 20000) {
        const router = [];
        this.blogHome = res.data.data;
        this.blogHome.forEach((item) => {
          router.push({
            path: item.path,
            name: item.category_name,
            component: () => import(`@/views/${item.vue_path}.vue`),
            meta: {
              content: "1111",
            },
          });
          if (item.path == this.$route.path) {
            document.title = item.seo_title;
            this.HeaderContent = item.seo_title;
          }
        });
        this.blogHome = this.blogHome.filter((item) => item.isShow == true);
        // localStorage.setItem("blogHome",JSON.stringify(this.blogHome))
        Cookies.set("blogHome", JSON.stringify(this.blogHome));
        this.$router.addRoutes(router);
      }
    },
    goToStateMap() {
      if (this.$route.name !== "Statemap") {
        this.$router.push({
          name: "Statemap",
        });
      }
    },
    handleCommand(command) {
      this.$i18n.locale = command; // 切换语言 
      this.currentLanguage = command; // 更新当前语言
    },
    messageButton() {
      this.$router.push("/messages");
    },
    deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      Cookies.remove(name);
    }
  },
  // logout() {
  //   this.deleteAllCookies(); // 调用删除所有 Cookies 的方法
  //   this.user = "";
  //   this.$message({
  //     type: "success",
  //     message: this.$t("Logged out"),
  //     duration: 2000,
  //   });
  //   this.$router.push("/login");
  // },
  logout() {
           // 清除所有Cookie
           const cookies = document.cookie.split(";");
           for (let i = 0; i < cookies.length; i++) {
               const cookie = cookies[i];
               const eqPos = cookie.indexOf("=");
               const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
               document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
           }
           this.$message({
            type: "success",
            message: this.$t("Logged out"),
            duration: 2000,
          });
          // this.$router.push("/login");
          window.location.reload()
           // 重定向到登录页面
          //  this.$router.push('/login');
    },
    My_Account() {
      this.$router.push("/dashboard");
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    handleOpen(id) {
      this.isOpen[id] = true;
    },
    handleClose(id) {
      this.isOpen[id] = false;
    },
    handleScroll() {
      const topNavList = document.querySelector(".top-navList");
      if (topNavList) {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 35) {
          topNavList.classList.add("fixed");
        } else {
          topNavList.classList.remove("fixed");
        }
      }
    },
    toggleCart() {
      // const token = localStorage.getItem("token");
      const token=Cookies.get("token")
      if (token) {
        if (this.$route.path === "/cart") {
          this.$router.go(0);
        } else {
          this.$router.push("/cart");
        }
      } else {
        // this.$router.push("/login");
        this.$router.push("/VerificationLogin?redirect="+btoa(this.$route.fullPath));
      }
    },
    async _postMessage() {
      // let user = JSON.parse(localStorage.getItem("userInfo"));
      // let user=Cookies.get("userInfo")
      let user = this.getUserInfoFromCookie();
      if (!user) {
        return;
      }
      const res = await postMessage({
        user_id: user.uuid,
      });
      if (res.data.code == 200) {
        this.messageList = res.data.data;
        this.messageCount = res.data.false_count;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      this.showHappy = false;
    }, 5000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.happyBox {
  width: 100%;
  height: 100%;
  background: #0f0f0fab;
  position: fixed;
  z-index: 9999;
}
@media screen and (min-width: 768px) {
   .happy { width: 50%; height: 55%; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 9999; }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
   .happy {
      width: 100%;
      height: 55%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
   }
}
@media screen and (max-width: 767px) {
   .happy { width: 100%; height: auto; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 9999; }
}
#English {
  cursor: pointer;
}
.homePage {
  background: #7eb63b;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 170px;
  height: 37px;
  line-height: 37px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 14px;
  z-index: 999;
}
.head-cart {
  font-size: 1rem;
}
.click_a {
  color: #7eb63b;
  cursor: pointer;
  text-decoration: underline;
  // text-un
}
.top-nav-menu ul {
  list-style: none !important;
}
.solution {
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 14px;
}
.solution p {
  margin: 0;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.message_icon {
  font-size: 30px;
  line-height: 50px;
  cursor: pointer;
}
.message-badge {
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 14px;
  background-color: red;
  color: white;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  span {
    font-size: 12px;
    transform: scale(0.8);
  }
}

.el-menu-item:hover,
.el-submenu__title:hover {
  color: #303133 !important;
  background: rgb(204, 204, 204);
}

.el-icon-arrow-down {
  font-size: 12px;
}
/* .nav-titles {
  color: white !important;
} */
.liSelects {
  font-size: 17px;
}
li {
  list-style: none;
}
ul {
  margin: 0 !important;
}
a {
  color: #333;
  text-decoration: none;
}
.liSelect {
  font-size: 17px;
  list-style: none;
}
.top-header {
  background: #f8f8f8;
  border-bottom: 1px solid #e5e5e5;
}
.top-header .top-header-user ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.top-header .top-header-user ul li {
  margin: 0 15px;
  font-size: 14px;
}
.navbar-dark {
  padding: 10px;
}
.top-header-user {
  line-height: 40px;
  li {
    position: relative;
  }
}
.banner,
.responsive-box {
  background: #7eb63b;
}
/* 确保没有其他 CSS 规则覆盖 v-show 的 display 样式 */
.collapse {
  display: none !important; /* 默认隐藏 */
}
.collapse.v-show {
  display: block !important; /* 显示时的样式 */
}
.collapse {
  display: block !important; /* 确保没有设置 display: none; */
}
.mobile-head {
  width: 100%;
  background: white;
}
.nav_scroll {
  width: 100%;
  overflow: auto;
}
.nav_scroll li {
  padding: 1rem 2rem;
  border-bottom: 1px solid #e5e5e5;
  color: #333 !important;
}
.header-area .top-nav .nav-title {
  font-size: 1.75rem;
  font-weight: 500;
  /* color: #ffffff; */
  line-height: 5.25rem;
}

.header-button {
  display: inline-block;
  text-align: center;
}
.header-area .top-nav #shoppingCart {
  cursor: pointer;
}
.el-menu--horizontal > .el-menu-item {
  flex: 1;
  text-align: center;
}
#shoppingCart {
  font-size: 30px;
  color: white;
  cursor: pointer; /* 鼠标划过时显示手形光标 */
}
.head-right {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.header-area .top-nav .head-right i {
  font-size: 1.5rem;
  position: relative;
}
.header-button a {
  background: #7eb63b;
  color: #fff;
  display: inline-block;
  padding: 12px 14px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  border: 1px solid #fff;
  font-size: 1rem;
}

.logo {
  display: flex;
  justify-content: center;
}
.footer-section {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section {
    text-align: center;
  }
}
.footer-widget-desc p {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    margin: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section .solar-conatct-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area p {
  opacity: 0.8;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  margin-top: 25px;
}
.original_box {
  border-bottom: 1px solid #e2e2e2;
  background: #fff;
  list-style: none !important;
}
.footer-section {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section {
    text-align: center;
  }
}
.footer-widget-desc p {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    margin: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section .solar-conatct-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.submenu-title {
  color: rgb(51, 51, 51) !important;
  font-size: 15px;
}
.el-menu.el-menu--horizontal {
  border: 0;
}
/*  */
.el-submenu__title {
  display: flex;
  padding: 0;
}

.el-submenu__title:hover {
  color: #7eb63b !important;
  background: #cecece !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}

.footer-section {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section {
    text-align: center;
  }
}
.footer-widget-desc p {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    margin: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section .solar-conatct-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area p {
  opacity: 0.8;
  font-size: 16px;
  line-height: 40px;
  /* color: #ffffff; */
  color: #7eb63b;
  margin-top: 25px;
}
.el-menu-item {
  /* padding: 0 50px; */
  /* margin: 0 50px; */
}
.row {
  margin-right: calc(var(--bs-gutter-x) * -0);
}

.el-menu--horizontal > .el-menu-item {
  line-height: 65px !important;
}
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  margin-top: 0 !important;
  color: red;
}
.child_select_name > li {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.align-items-center {
  align-items: center;
}
.top-header-infor {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 40px;
  justify-content: flex-start;
}
.el-menu.el-menu--popup {
  .el-menu-item.is-active {
    background: rgb(204, 204, 204) !important;
  }
}
</style>
