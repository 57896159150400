export default {
  message: {
    // headerList
    en: 'English',
    de: 'German',
    logout: 'Log out',
    One_StopSolarProductsIntegrator: 'One-Stop Solar Products Integrator',
    Get_A_Quote: "Get your free quote today",
    // index
    Four_Steps_for_a_Successful_Project: "Four Steps for a Successful Project",
    PROJECT_COMPLETION_TIMELINE: "PROJECT COMPLETION TIMELINE",
    Residential_business_Days: "Residential: 3 - 5 business Days",
    Business_Days: "7 - 10 Business Days",
    Step_1: "Step 1",
    Step_2: "Step 2",
    Step_3: "Step 3",
    Step_4: "Step 4",
    step_1_desc: "Consult an Expert",
    step_2_desc: "Personalized Solution Design",
    step_3_desc: "Place Orders Online",
    step_4_desc: "Construction and Installation",
    Read_More_About_How_to_Purchase: "Read More About How to Purchase",
    All_In_One_Solutions: "All In One Solutions",
    minutes_is_the_time_span_for_sunlight_to_reach_the_blue_planet: "8-minutes is the time span for sunlight to reach the blue planet",
    At_this_moment_everything_is_reviving_and_thriving: "At this moment, everything is reviving and thriving",
    The_innovation_of_light_and_electricity_is_endless: "The innovation of light and electricity is endless",
    The_birth_and_popularization_of_new_materials_have_brought_smart_energy_projects_into_thousands_of_households: "The birth and popularization of new materials have brought smart energy projects into thousands of households",
    Photovoltaics_are_the_future: " Photovoltaics are the future",
    Sevice_Process: "Sevice Process",
    Solar_Consulting_Expertise: "Solar Consulting Expertise",
    In_addition_to_its_off_the_shelf: "In addition to its off-the-shelf products, SolarEdge provides customized solutions that cater to each customer's unique requirements.",
    Read_More: "Read More",
    Door_to_Door_Survey:"Door-to-Door Survey",
    This_ensures_that_the_preliminary:"This ensures that the preliminary design is feasible by confirming measurements and obtaining other relevant information required to plan the project.",
    Install_Monitoring:"Install & Monitoring",
    In_addition_to_its_off_the_shelf_productsL:"In addition to its off-the-shelf products, SolarEdge provides customized solutions that cater to each customer's unique requirements.",
    // fiiter  底部翻译内容样式
    Contact_Us:"Contact Us",
    charging_station:"Easy Solar Supply offers professional consulting services, professional knowledge of PV power plant and charging station solutions, and full life cycle O&M capabilities.",
    Easy_Solar_Supply:"Easy Solar Supply",
    More_Power_Of_Light_Keep_Your_Home_Always_Bright:"More Power Of Light, Keep Your Home Always Bright",
    Call_Us_Anytime:"Call Us Anytime",
    Call_Us_Phone:"+919 741 1511",
    Company:"Company",
    About_Us:"About Us",
    Company_Introduction:"Company Introduction",
    Legal_Provisions:"Legal Provisions",
    Problem_Feedback:"Problem Feedback",
    Friendship_link:"Friendship link",
    Renewable_Energy:"Renewable Energy",
    PV_Energy:"PV Energy",
    Installation_Manual:"Installation Manual",
    Monitoring_System:"Monitoring System",
    Our_Email:"Our Email",
    Get_the_latest_news_and_discounts_in_your_inbox:"Get the latest news and discounts in your inbox",
    Submits:"Submit",
    Copyright:"Copyright",
    Easy_Solar_Supply_All_rights_reserved:" 2024.Easy Solar Supply All rights reserved.",
    // residential
    Solution_for_residential_energy_storage_system:"Solution for residential energy storage system",
    The_integrated_solution_for_solar_energy_storage:"The integrated solution for solar energy storage and charging designed for household building roofs or PV car sheds is generally connected to a 480V low-voltage power grid, with the option of self use  and surplus power grid mode. The power range of PV modules is 25-150kW, and they can be equipped with 10-50kWh energy storage batteries and AC/DC charging piles.",
    Home_PV_Green_Power_Packages:"Home PV Green Power Packages",
    gird_PPVs_Sstorage_Ccharging:"G:gird P:PVs S:storage C:charging",
    Annual_Power_Generation:"Annual Power Generation:",
    Construction_Mode_Optional:"Construction Mode Optional:",
    Pv_Panels_Optional:"Pv Panels Optional:",
    Rated_Grid_Voltage:"Rated Grid Voltage:",
    Installation_area:"Installation area:",
    Number_of_blocks:"Number of photovoltaic panels:",
    Residential_power_plant_solution:"Residential power plant solution",
    Our_intelligent_components:"Our intelligent components",
    Maximize_Every_Ray_Of_Sunshine:"Maximize Every Ray Of Sunshine",
    EasySolar_Home_optimizes_each_solar_panel_individually:"EasySolar Home optimizes each solar panel individually, ensuring maximum energy generation even on cloudy days.",
    Residential_Inverters:"Residential Inverters",
    Our_smart_energy_managers_optimize_the_home:"Our smart energy managers optimize the home's energy flow,maximizing the amount of solar power produced, stored, and consumed - day and night.",
    Storage_And_Backup:"Storage And Backup",
    Our_highly_efficient_DCcoupled_Batteries:"Our highly efficient DC-coupled Batteries store excess solar energy for powering the home when rates are high or at night. When installed with our Backup Interface, they provide reliable backup power during outages.",
    Home_EV_Charger:"Home EV Charger",
    Home_EV_charger_is_a_level_two_charger_that_offers_the_flexibility_to_function :"Home EV charger is a level two charger that offers the flexibility to function independently or seamlessly integrate with the EasySolar Home Hub, enabling up to 25% faster charging using clean,affordable solar energy.",
    Smart_Control_At_Your_Fingertips:"Smart Control At Your Fingertips",
    Take_complete_control_of_your_home_energy_ecosystem_with_our_user_friendly:"Take complete control of your home energy ecosystem with our user-friendly app. Optimize your energy usage in real-time and monitor your system performance.",
    Save_More_Energy:"Save More Energy",
    Cost_Saving:"Cost Saving",
    Save_electricity_cost_and_reduce_your_dependence_on_the_grid:"Save electricity cost and reduce your dependence on the grid.",
    Green_Power_With_Enhanced_Safety:"Green Power With Enhanced Safety",
    Enhanced_Safety:"Enhanced Safety",
    Meet_the_higher_safety_standard_ensure_personnel_and_property_safety:"Meet the higher safety standard, ensure personnel and property safety.",
    Your_Energy:"Your Energy",
    Management_Assistant:"Management Assistant",
    Your_Smart_Energy_Home:"Your Smart Energy Home",
    Predict_your_system_generation_and_consumption:"Predict your system generation and consumption to ensure better energy management.",
    Elegant_Design_To_Brighten_Your_Home:"Elegant Design To Brighten Your Home",
    Less_Is_More:"Less Is More",
    Modern_design_fits_with_your_home_style_with_elegance:"Modern design fits with your home style with elegance.",
    No_Worry_about_Power_Blackout_Enjoy_Every_Moment:"No Worry about Power Blackout, Enjoy Every Moment",
    Home_Battery_Backup:"Home Battery Backup",
    Enjoy_every_moment_without_worrying_about_power_blackout:"Enjoy every moment, without worrying about power blackout.",
    // Commercial
    Commercial_and_Industrial_Solar_System:"Commercial and Industrial Solar System",
    Installing_a_rooftop_solar_system_on_your:"Installing a rooftop solar system on your commercial building can significantly reduce your energy costs,  help you avoid future energy uncertainty, and is a very public way to demonstrate your commitment to  fulfilling your company's sustainability goals.  Easy Solar's smart commercial PV solutions are a true business game-changer. They're designed to generate  more  energy from each panel, providing faster return on your investment and more profitability than conventional  solar energy systems. All while ensuring your business continuity..",
    Commercial_PV_Green_Power_Packages:"Commercial PV Green Power Packages",
    Grepresents_gird_Prepresents_PVs_Srepresents_storage_Crepresents_charging:"G: represents gird P:represents PVs S: represents storage C: represents charging",
    Annual_Power_Generation: "Annual Power Generation: ",
    Construction_Mode_Optional: "Construction Mode Optional:",
    Pv_Panels_Optional: "Pv Panels Optional:",
    Rated_Grid_Voltage: "Rated Grid Voltage:",
    Installation_area: "Installation area:",
    Number_of_blocks: "Number of photovoltaic panels:",
    Commercial_power_plant_solution: "Commercial power plant solution",
    Our_intelligent_components: "Our intelligent components",
    Smart_Module_Controller:"Smart Module Controller",
    A_Wonderful_Option_for_Your_Business:"A Wonderful Option for Your Business.  Start to make full use of the roof area and produce clean energy.  Smart Module Controllers monitor your PV modules in real time to give you the visibility and confidence  you deserve.",
    Your_Magic_PV_Box:"Your Magic PV Box. Smart PV Controllers protect the safety of your business and property.",
    Smart_Storage_Batteries:"Smart Storage Batteries",
    Your_Reliable_Power_Bank_Energy_storage:"Your Reliable Power Bank. Energy storage systems store solar energy for the night or a rainy day without wasting any power your PV  generated.",
    Commercial_Fast_Charging:"Commercial Fast Charging",
    While_maximizing_the_profitability_of_parking_areas_is_a_primary_goal:"While maximizing the profitability of parking areas is a primary goal,  ensuring the safety of people and assets in the vicinity of carport PV systems is critical. Our PV systems  with integrated EV charging points meet the industry's most stringent safety standards, with a complete  package of built-in safety features.",
    Property_On_The_Hand:"Property On The Hand",
    Combining_with_Smart_PV_controller:"Combining with Smart PV controller, details of each modules can be visible and checked anytime and anywhere, protecting your own property.",
    Unlock_the_Potentials_of_Your_Rooftop:"Unlock the Potentials of Your Rooftop",
    Utilize_Your_Rooftop:"Utilize Your Rooftop",
    Start_to_make_full_use_of_the_roof_area_and_produce_clean_energy:"Start to make full use of the roof area and produce clean energy.",
    Generate_More_Store_More_Earn_More:"Generate More, Store More, Earn More",
    Energize_Your_Earnings:"Energize Your Earnings",
    Supply_emergent_power_when_power_outage:"ESS Integrated, Supply emergent power when power outage, ensure power security",
    Ensure_Your_Safety_With_Leading_Protection:"Ensure Your Safety With Leading Protection",
    Industry_leading_Protection:"Industry-leading Protection",
    From_PV_to_ESS_System_Level_Safety_Protection_to_ensure_personal_and_property_safety:"From PV to ESS, System Level Safety Protection, to ensure personal and property safety.",
    Superior_Quality_Built_to_Last_for_Decades:"Superior Quality, Built to Last for Decades",
    Excellent_Quality_Reliability:"Excellent Quality & Reliability",
    Solar_System_will_continue_to_produce_clean_energy_for_your_company_for_decades:"Solar System will continue to produce clean energy for your company for decades.",
    AI_Boost_Empowers_You_with_Simple_OM:"AI Boost Empowers You with Simple OM",
    Guarantees_You_Simple_OM:"Guarantees You Simple OM",
    Built_Smart_Brings_You_Simple_OM_AI_Boost_Brings_You_Simple_OM:"Built-in Smart Brings You Simple OM  AI Boost Brings You Simple OM.",
    // Design
    Propose_all_your_solar_sales_with_Easy_Solar:"Propose all your solar sales with Easy Solar",
    We_are_capable_of_delivering_the_Solar_Sales_proposal_in_just:"We are capable of delivering the Solar Sales proposal in just 2 hours* of the request. The proposal shows  the overall savings for each project (Residential or Commercial) taking into consideration obstructions  such as attic vents, exhaust pipes, chimneys, etc.",
    sign_up_now:"sign up now",
    Sales_Proposal:"Sales Proposal",
    Site_Survey:"Site Survey",
    Plan_Set:"Plan Set",
    Engineering_Review:"Engineering Review",
    PTO:"PTO",
    Permitting:"Permitting",
    Get_detailed_solar_sales_proposals_created_using:"Get detailed solar sales proposals created using LlDAR and GoogleMaps images.  These proposals will help you present your sales pitch with confidence and effectively discuss the benefits of solarinstallation with the project owner. Our designs can be customizedto  meet your specifications, and we can seamlessly integrate themwith the solar software you are currently using.",
    Get_Sales_Proposal_within_hours:" Get Sales Proposal within 2 hours* of request and each proposal indicates savings for each project (residential or commercial), taking into consideration obstructions such as attic vents, exhaust pipes, chimneys, etc. The design shows how the solar array will appear in your home.",
    Our_Detailed_Report_Includes:"Our Detailed Report Includes",
    With_a_detailed_Solar_Sales_proposal_created_using_LIDAR:"With a detailed Solar Sales proposal created using LIDAR and Google maps images, you can be confident of each Sales pitch.",
    Site_Assessments:"Site Assessments",
    LIDAR_Overlay:"3D LIDAR Overlay",
    Aurora_Shade_Report:"Aurora Shade Report",
    Street_View_3D_Model:"Street View 3D Model",
    Shade_Stats:"Shade Stats",
    Analysis:"Analysis",
    Maximum_PV_System_Size:"Maximum PV System Size",
    Roof_Slope_and_Azimuth:"Roof Slope and Azimuth",
    String_Sizing_Electrical_Characteristics:"String Sizing & Electrical Characteristics",
    Estimated_Power_Production:"Estimated Power Production",
    Equipment_Description:"Equipment Description",
    Installation_Area_Length_and_Width:"Installation Area (Length and Width)",
    // Purchase
    Four_Steps_To_A_Successful_Purchase:"Four Steps To A Successful Purchase",
    Try_our_easy_solar_calculator:"Try our easy solar calculator! Just upload your electricity bill and our system will automatically suggest the best solar panel package for you. Please leave your email and phone number so we can get in touch with you.",
    solar_calculator:"solar calculator",
    Complete_Solar_Consulting_Expertise:"Complete Solar Consulting Expertise",
    Site_and_Sunlight_Analysis:"Site and Sunlight Analysis",
    Wiring_Size_Calculations:"Wiring Size Calculations",
    System_Capacity_Estimation:"System Capacity Estimation",
    Grid_Connection_Design:"Grid Connection Design",
    Value_Optimization:"Value Optimization",
    Ready_to_see_how_solar_can_power_your_home:"Ready to see how solar can power your home? ",
    Get_a_personalized_proposal_within_24_hours:"Get a personalized proposal within 24 hours. Our report will show you exactly how much you can save by switching to solar, taking into account any chimneys, vents, or the angle of your roof. We'll even create a visualization of how the solar panels will look on your home.",
    Heres_what_youll_find_in_your_free_solar_proposal:"Here's what you'll find in your free solar proposal:",
    Solar_Panel_Capacity_Discover:"Solar Panel Capacity: Discover how much solar energy your roof can generate.",
    Optimized_Roof_Layout_Well_consider_your_roof:"Optimized Roof Layout: We'll consider your roof's slope and sun exposure to create the most efficient design.",
    Smart_System_Design_Learn_about_the_wiring:"Smart System Design: Learn about the wiring and electrical components that will power your home.",
    Estimated_Energy_Production:"Estimated Energy Production: See how much electricity your solar panels will generate each year.",
    Equipment_Details_Get_a_breakdown_of_the_high:"Equipment Details: Get a breakdown of the high-quality panels and equipment we use.",
    Clear_Installation_Plan_Understand_exactly:"Clear Installation Plan: Understand exactly how much space the panels will take up on your roof",
    Our_Solar_Design_Service:"Our Solar Design Service",
    Upon_receiving_an_order_we_promptly_review_local_regulations:"Upon receiving an order, we promptly review local regulations and conduct an initial site and shading analysis to ensure an efficient design for your new solar energy system. Throughout the process we maintain close communication with you, local inspectors, and product manufacturers to ensure your project is completed smoothly and quickly and to your exact expectations.",
    Complete_Solar_Energy_Design_Suite:"Complete Solar Energy Design Suite: ",
    Detailed_Site_Layout_and_Plans:"Detailed Site Layout and Plans",
    Structural_Blueprints:"Structural Blueprints",
    Single_Line_Electrical_Diagrams:"Single-Line Electrical Diagrams",
    Three_Line_Electrical_Diagrams:"Three-Line Electrical Diagrams",
    Safety_Labeling_Plans:"Safety Labeling Plans",
    Our_expert_team_handles_every_detail:"Our expert team handles every detail:",
    Engineering_Design_We_create_a_customized_solar_plan_optimized:"Engineering & Design: We create a customized solar plan optimized for your roof and energy needs.",
    Collaborative_Design_Reviews: "Collaborative Design Reviews: We work closely with you to ensure the design meets your expectations.",
    Detailed_Design_Blueprints:"Detailed Design Blueprints: We provide clear documentation for future reference and maintenance.",
    Comprehensive_Bill_of_Materials:"Comprehensive Bill of Materials: You'll have a transparent breakdown of all the components used in your system.",
    Seamless_Permitting_Approvals:"Seamless Permitting & Approvals: We navigate the permitting process efficiently, saving you time and hassle.",
    Complete_Communication:'Complete Communication: We keep all parties informed throughout the installation.',
    Post_Installation_Support:"Post-Installation Support",
    Upon_receiving_an_order:"Upon receiving an order, we promptly review local regulations and conduct an initial site and shading analysis to ensure an efficient design for your new solar energy system. Throughout the process we maintain close communication with you, local inspectors, and product manufacturers to ensure your project is completed smoothly and quickly and to your exact expectations.",
    Reliable_Performance_Backed_by_over_30_years_of_Experience:"Reliable Performance, Backed by over 30 years of Experience",
    Our_experts_understand_the_importance_of_ongoing_system_health:"Our experts understand the importance of ongoing system health.  Our commitment to quality extends beyond installation — we're here to ensure your solar system delivers the clean energy you expect.",
    Effortless_Monitoring_Control:"Effortless Monitoring & Control",
    Real_Time_System_Insights:"Real-Time System Insights: Gain instant access to the performance of your system  through a user-friendly online monitoring platform.",
    Intelligent_Monitoring:'24/7 Intelligent Monitoring: Our intelligent system keeps a watchful eye on your solar panels, automatically detecting and notifying you of any potential issues.',
    Remote_System_Management:"Remote System Management: Enjoy the convenience of remote upgrades and parameter adjustments, ensuring your system stays optimized.",
    one:"01",
    two:"02",
    three:"03",
    four:"04",
    five:"05",
    six:"06",
    // Service 
    // 重复
    Complete_Solar_Consulting_Expertise:"Complete Solar Consulting Expertise",
    Try_our_easy_solar_calculator:"Try our easy solar calculator! Just upload your electricity bill and our system will automatically suggest the best solar panel package for you. Please leave your email and phone number so that we can get in touch with you.",
    Solar_Calculator:"Solar Calculator",
    Find_a_Consultant:"Find a Consultant",
    Door_to_door_survey:"Door-to-door survey",
    Get_a_personalized_proposal_within_24_hours:"Get a personalized proposal within 24 hours. Our report will show you exactly how much you can save by switching to solar, taking into account any chimneys, vents, or the angle of your roof. We'll even create a visualization of how the solar panels will look on your home.",
    // Read_More: "Read More",//重复
    Design_Package_Recommendations:"Design & Package Recommendations",
    At_EasySolar_we_know_the_importance_of_expertise:"At EasySolar, we know the importance of expertise needed in any home upgrades. It is crucial for performance and savings to select the ideal solar panel installer . We're committed to a seamless installation process, delivering excellence every step of the way.",
    Permit_Installation:"Permit & Installation",
    Upon_receiving_an_order:"Upon receiving an order, we promptly review local regulations and conduct an initial site and shading analysis to ensure an efficient design for your new solar power system. Throughout the process we maintain close communication with you, local inspectors, and product manufacturers to ensure your project is completed smoothly and quickly and to your exact expectations.",
    With_EasySolar:" With EasySolar, you can rest assured that your solar panel installation is in the hands of a trusted and experienced team. Let us handle the complexities while you enjoy the benefits of clean, sustainable energy.",
    Effortless_Monitoring_Control:"Effortless Monitoring & Control",
    // Real_Time_System_Insights:"Real-Time System Insights: Gain instant access to the performance of your system through a user-friendly online monitoring platform.",
    // 重复 而且我俩还有可能改同一个文件夹
    // Intelligent_Monitoring:"24/7 Intelligent Monitoring: Our intelligent system keeps a watchful eye on your solar panels, automatically detecting and notifying you of any potential issues.",
    // Remote_System_Management:"Remote System Management: Enjoy the convenience of remote upgrades and parameter adjustments, ensuring your system stays optimized.",
  }
}
// 