import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';

import router from './router'; // 引入路由配置
import store from './store';
// Vue.prototype.$_Boa_Pay_Url = 'https://testsecureacceptance.cybersource.com/pay';//测试
Vue.prototype.$_Boa_Pay_Url = 'https://secureacceptance.merchant-services.bankofamerica.com/embedded/pay';//生产

// CSS 导入
import '/public/css/index.css';
import '/public/css/iconfont.css';
import '/public/css/oppoSans.css';
import "/public/css/footer.css";
import '../public/bootstrap-5.0.2-dist/css/bootstrap.min.css';
import 'bootstrap';
import 'jquery/dist/jquery.min.js';
import 'toastr/build/toastr.min.css';
import './assets/custom-styles.css'; // 引入自定义样式文件
import 'vue-slider-captcha/lib/vue-slider-captcha.css';

// 第三方库导入
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueI18n from 'vue-i18n';
import * as echarts from 'echarts';
import * as VueGoogleMaps from 'vue2-google-maps';
import toastr from 'toastr';
import SlideVerify from 'vue-monoplasty-slide-verify';
import htmlToPdf from '@/utils/htmlToPdf'
import $ from 'jquery';
window.$ = window.jQuery = $;

// Quill Editor 样式
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// 国际化语言包
import English from './i18n/en';
import German from './i18n/de';

// 自定义组件
import App from './App.vue';
import PageDashboard from './components/PageDashboard.vue';
import ComTable from './components/ComTable.vue';

// Mixin 和过滤器
import globalMixin from './mixins/globalMixin'; // 引入Mixins
import '@/utils/filter';

// 全局属性挂载
Vue.prototype.$uploadBaseUrl = 'http://192.168.1.90:8000/api/blog/upload_file_api';
// Vue.prototype.$uploadBaseUrl = 'https://easysolarsupply.com/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/file/a/upload';
Vue.prototype.$echarts = echarts;
Vue.prototype.$residentialProductId = 'ca1b7e06-7b39-436b-b538-bafc9feb37de';
Vue.prototype.$commercialProductId = 'cd02c153-69e8-4b2e-aef7-f27c0bef917b';

// 全局组件注册
Vue.component('PageDashboard', PageDashboard);
Vue.component('ComTable', ComTable);

// 全局插件使用
Vue.use(ElementUI, { locale });
Vue.use(htmlToPdf)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAsjDyAQFKHrRgU1ceGWNVTXjNdMZsv7ts', // 替换为你的 Google Maps API 密钥
    libraries: 'places,geometry'
  }
});
Vue.prototype.$globalState={
  isRouterPathPosted:true,
  lastCalledPath:null
}
Vue.use(SlideVerify);
Vue.use(CKEditor);
Vue.use(VueI18n);

// 注册全局 mixin
Vue.mixin(globalMixin);

// 全局配置
Vue.config.productionTip = false;

// 国际化配置
const messages = {
  en: English,
  de: German
};

const i18n = new VueI18n({
  locale: 'en', // 默认语言设置为 'en'
  messages
});

// 创建 Vue 实例
new Vue({
  i18n,
  el: '#app',
  router, // 使用路由
  store, // 使用 Vuex
  render: h => h(App),
}).$mount('#app');

// 全局变量
window.toastr = toastr;

